<script>
import useVuelidate from '@vuelidate/core'
import {maxLength, required, requiredIf} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkDateTime from "@components/input/AkDateTime";
import AkTime from "@components/input/AkTime";
import AkDropdown from "@components/input/AkDropdown";
import AkInputText from "@components/input/AkInputText";
import AkInputTextArea from "@components/input/AkInputTextArea";
import AkFileUpload from "@components/input/AkFileUpload";
import AkDialog from "@components/general/AkDialog";
import AkCheckboxButton from "@components/input/AkCheckboxButton";
import AkYesNoButton from "@components/input/AkYesNoButton";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import dateFormatter from "@mixins/dateFormatter"
import roleMixin from "@mixins/roleMixin";
import utilsMixin from "@mixins/utilsMixin";
import typeVhConst from "@mixins/const/typeVhConst";

/* SERVICES */
import forecastSheetService from "@services/forecastSheetService";
import rolePermissionService from "@services/rolePermissionService";
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import fileService from "@services/fileService";

export default {
  components: {
    AkYesNoButton,
    AkFileUpload,
    AkFormView,
    AkFormSubmitted,
    AkDateTime,
    AkDropdown,
    AkInputTextArea,
    AkTime,
    AkInputText,
    AkDialog,
    AkCheckboxButton
  },
  mixins: [randomRef, dateFormatter, roleMixin, utilsMixin, typeVhConst],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "forecast_sheet.create",
    }
  },
  data() {
    return {
      submitted: false,
      validationSubmitted: false,
      deleteIndex: null,
      agerList: [],
      uerList: [],
      ceiList: [],
      disableAger: null,
      disableUer: null,
      disableCei: null,
      showTypeVh: false,
      current: {
        ceiId: null,
        uerId: null,
        agerId: null,
        typeVh: null,
        weekend: false,
        motif: null,
        date: new Date(),
        patrolStart: null,
        curativeStart: null,
        precurativeStart: null,
        forecastObservation: "",
        interventionObservation: "",
      },
      forecastRows: [],
      files: null,
      additionalFiles: null,
    }
  },
  validations() {
    return {
      current: {
        date: {required},
        agerId: {required},
        uerId: {required},
        ceiId: {required},
        typeVh: {required: requiredIf(this.showTypeVh)},
        motif: {maxLength: maxLength(5000)},
        forecastObservation: {maxLength: maxLength(5000)},
        interventionObservation: {maxLength: maxLength(5000)},
      },
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let p1 = agerService.findAllCurrent();
    p1.then(data => this.agerList = data);

    let p2 = uerService.findAllCurrent();
    p2.then(data => this.uerList = data);

    let p3 = ceiService.findAllCurrent();
    p3.then(data => this.ceiList = data);

    let p4 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.forecast.edit);

    Promise.all([p1, p2, p3, p4]).then(() => {
      this.initData();
      this.getRef().hideLoader();
    });
  },
  methods: {
    initData() {
      if (this.currentUser.agerId) {
        this.disableAger = true;
        this.current.agerId = this.currentUser.agerId;
      }
      if (this.currentUser.uerId) {
        this.disableUer = true;
        this.current.uerId = this.currentUser.uerId;
      }
      if (this.currentUser.ceiId) {
        this.disableCei = true;
        this.current.ceiId = this.currentUser.ceiId;
      }
      this.current.createdById = this.currentUser.userId;
      this.current.tel = this.$store.state.userInformation.tel;
      this.initForecastRows();
    },

    initForecastRows() {
      const hours = [17, 20, 23, 2, 5, 8, 10, 12, 17];
      for (const element of hours) {
        this.forecastRows.push({
          hour: element + ":00",
          airTemp: null,
          groundTemp: null,
          humidity: null,
        });
      }
    },

    createSheet() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("forecast_sheet.error.create"));
        return;
      }

      if (!this.currentDateFriday) {
        this.current.weekend = false;
        this.current.motif = null;
      }

      this.getRef().showLoader(this.$refs.form)
      let dto = {
        forecastSheet: this.current,
        forecastRows: this.forecastRows,
        validated: this.validationSubmitted
      };
      forecastSheetService.create(dto).then(data => {
        this.saveFiles(data);
        this.goToDetails(data);
      }).catch(this.displayError);
    },
    createAndValidate() {
      this.validationSubmitted = true;
      this.createSheet();
    },
    saveFiles(data) {
      let formData = new FormData();

      if (this.files && this.files[0]) {
        this.files.forEach(f => formData.append('meteoFiles', f));
      }
      if (this.additionalFiles && this.additionalFiles[0]) {
        this.additionalFiles.forEach(f => formData.append('additionalFiles', f));
      }

      if (this.files && this.files[0] || this.additionalFiles && this.additionalFiles[0]) {
        Promise.all([fileService.createForecastSheetFile(formData, data.forecastSheet.id)]).then();
      }
    },
    goToDetails(data) {
      let msg = this.$t(this.validationSubmitted ? "forecast_sheet.added_validated" : "forecast_sheet.added");
      this.$router.replace({path: `/forecast/${data.forecastSheet.id}/details`, query: {msg: msg}});
    },
    displayError(e) {
      this.getRef().hideLoader();
      this.getRef().error(this.$t("error." + e.response.data.error));
      this.submitted = false;
      this.validationSubmitted = false;
      this.$refs.dialogValidate.hide();
    },
    deleteSchedule(rowIndex) {
      this.forecastRows.splice(rowIndex, 1);
    },
    openDialogValidate() {
      if (!this.checkForm()) return;
      this.$refs.dialogValidate.show();
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    },
    addRow() {
      this.forecastRows.push({
        hour: "0:00",
        airTemp: null,
        groundTemp: null,
        humidity: null,
      });
    },
    checkHour(rowIndex) {
      this.forecastRows[rowIndex].hour = this.formatHourFromTextInput(this.forecastRows[rowIndex].hour);
    },
    checkAirTemp(rowIndex) {
      let airTemp = this.forecastRows[rowIndex].airTemp;
      this.forecastRows[rowIndex].airTemp = this.checkBetween(airTemp, -100.0, 100.0);
    },
    checkGroundTemp(rowIndex) {
      let groundTemp = this.forecastRows[rowIndex].groundTemp;
      this.forecastRows[rowIndex].groundTemp = this.checkBetween(groundTemp, -100.0, 100.0);
    },
    checkHumidityRate(rowIndex) {
      let humidity = this.forecastRows[rowIndex].humidity;
      this.forecastRows[rowIndex].humidity = this.checkBetween(humidity, 0.0, 100.0);
    },
    roundAirTemp(rowIndex) {
      let row = this.forecastRows[rowIndex];
      if (row.airTemp) row.airTemp = row.airTemp.toFixed(1);
    },
    roundGroundTemp(rowIndex) {
      let row = this.forecastRows[rowIndex];
      if (row.groundTemp) row.groundTemp = row.groundTemp.toFixed(1);
    },
    roundHumidityRate(rowIndex) {
      let row = this.forecastRows[rowIndex];
      if (row.humidity) row.humidity = row.humidity.toFixed(1);
    },
    focusInput(event) {
      let input = event.target.querySelectorAll('input')[0];
      if (input) input.focus();
    },
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    },
    userFullName() {
      return this.$store.state.userInformation.firstName + " " + this.$store.state.userInformation.lastName;
    },
    userTel() {
      return this.$store.state.userInformation.tel;
    },
    currentDateFriday() {
      return this.findDayOfWeek(this.current.date) === 5;
    },
  },
  watch: {
    uerListForCurrent() {
      if (!this.uerListForCurrent.some(uer => uer.id === this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if (!this.ceiListForCurrent.some(cei => cei.id === this.current.ceiId))
        this.current.ceiId = undefined;
    },
    'current.ceiId'(newVal) {
      if (undefined === newVal || null === newVal) {
        this.showTypeVh = false;
      } else this.showTypeVh = this.ceiList.filter(c => c.id === newVal)[0].displayTypeVh;
    },
    'current.date'(newVal) {
      this.friday = this.findDayOfWeek(newVal) === 5;
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('forecast_sheet.create')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkDateTime v-model="current.date"
                      :label="$t('date')"
                      :showTime=false
                      :validator="v$.current.date"
                      class-name="col-md-3"/>
          <AkDropdown v-model="current.agerId"
                      :disabled=disableAger
                      :label="$t('ager_label')"
                      :options=this.agerList
                      :submitted="this.submitted"
                      :validator="v$.current.agerId"
                      class-name="col-md-3"
                      option-label="label"
                      option-value="id"/>
          <AkDropdown v-if="current.agerId"
                      v-model="current.uerId"
                      :disabled=disableUer
                      :label="$t('uer_label')"
                      :options=this.uerListForCurrent
                      :submitted="this.submitted"
                      :validator="v$.current.uerId"
                      class-name="col-md-3"
                      option-label="label"
                      option-value="id"/>
          <AkDropdown v-if="current.uerId"
                      v-model="current.ceiId"
                      :disabled=disableCei
                      :label="$t('cei_label')"
                      :options=this.ceiListForCurrent
                      :submitted="this.submitted"
                      :validator="v$.current.ceiId"
                      class-name="col-md-3"
                      option-label="label"
                      option-value="id"/>
        </div>
        <div v-if="showTypeVh" class="form-row">
          <AkCheckboxButton v-model="current.typeVh"
                            :label="$t('type_vh_label')"
                            :multiple="false"
                            :options=typeVhConst
                            :submitted="this.submitted"
                            :validator="v$.current.typeVh"
                            class-name="col-md-6"/>
        </div>
        <div class="form-row">
          <AkInputText v-model="userFullName"
                       :disabled="true"
                       :label="$t('forecast_sheet.creator_name')"
                       class-name="col-md-6"/>
          <AkInputText v-model="userTel"
                       :disabled="true"
                       :label="$t('forecast_sheet.creator_tel')"
                       class-name="col-md-6"
                       placeholder=" "/>
        </div>

        <h5 class="mt-2">{{ $t('forecast_sheet.weather_forecast') }}</h5>
        <span class="btn btn-xs btn-inverse-primary" style="margin-bottom: 10px" @click="addRow">
          <i class="fe fe-plus"/>
          {{ $t('forecast_row.add_row') }}
        </span>
        <div class="form-row" style="padding-bottom: 20px">
          <div class="col-lg-12">
            <div class="table-responsive forecast-table">
              <table>
                <thead>
                <tr>
                  <th>{{ $t('forecast_sheet.hours') }}</th>
                  <th v-for="(row, index) in forecastRows" :key="row.order" @click="focusInput($event)">
                    <input v-model="row.hour" maxlength="5" size="5" @focusout="checkHour(index)"/>
                    <span class="btn btn-xs btn-inverse-danger" @click="deleteSchedule(index)">
                        <i class="fe fe-trash"/>
                    </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ $t('forecast_row.air_temp') }}</td>
                  <td v-for="(row, index) in forecastRows" :key="row.order" @click="focusInput($event)">
                    <input v-model="row.airTemp"
                           max="100" min="-100" step="0.1" type="number"
                           @focusout="roundAirTemp(index)"
                           @input="checkAirTemp(index)"/>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('forecast_row.ground_temp') }}</td>
                  <td v-for="(row, index) in forecastRows" :key="row.order" @click="focusInput($event)">
                    <input v-model="row.groundTemp"
                           max="100" min="-100" step="0.1" type="number"
                           @focusout="roundGroundTemp(index)"
                           @input="checkGroundTemp(index)"/>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('forecast_row.humidity') }}</td>
                  <td v-for="(row, index) in forecastRows" :key="row.order" @click="focusInput($event)">
                    <input v-model="row.humidity"
                           max="100" min="0" step="0.1" type="number"
                           @focusout="roundHumidityRate(index)"
                           @input="checkHumidityRate(index)"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="form-row">
          <AkInputTextArea v-model="current.forecastObservation"
                      :label="$t('forecast_sheet.forecast_observation')"
                      :submitted="this.submitted"
                      :validator="v$.current.forecastObservation"
                      class-name="col-md-12"
                      placeholder=" "/>
        </div>
        <div class="form-row">
          <AkFileUpload v-model="files"
                        :label="$t('forecast_sheet.files')"
                        class-name="col-md-4"/>
        </div>
        <h5 class="mt-2">{{ $t('forecast_sheet.intervention_forecast') }}</h5>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-row">
              <AkTime v-model="current.patrolStart"
                      :label="$t('forecast_sheet.patrol_start')"
                      class-name="col-md-12"
                      placeholder=" "/>
              <AkTime v-model="current.precurativeStart"
                      :label="$t('forecast_sheet.precurative_start')"
                      class-name="col-md-12"
                      placeholder=" "/>
              <AkTime v-model="current.curativeStart"
                      :label="$t('forecast_sheet.curative_start')"
                      class-name="col-md-12"
                      placeholder=" "/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-row">
              <AkInputTextArea v-model="current.interventionObservation"
                    :label="$t('forecast_sheet.intervention_observation')"
                    :rows=9
                    :submitted="this.submitted"
                    :validator="v$.current.interventionObservation"
                    class-name="col-md-12"
                    placeholder=" "/>
            </div>
          </div>
        </div>
        <div v-if="currentDateFriday">
          <h5 class="mt-2">{{ $t('forecast_sheet.weekend_activation') }}</h5>
          <div class="form-row">
            <AkYesNoButton v-model="current.weekend"
                           :label="$t('forecast_sheet.weekend')"
                           :multiple="false"
                           :submitted="this.submitted"
                           :validator="v$.current.weekend"
                           class-name="col-md-2"/>
          </div>
          <div class="form-row">
            <AkInputTextArea v-model="current.motif"
                             :label="$t('forecast_sheet.weekend_motif')"
                             :submitted="this.submitted"
                             :validator="v$.current.motif"
                             class-name="col-md-12"
                             placeholder=" "/>
          </div>
          <div class="form-row">
            <AkFileUpload v-model="additionalFiles"
                          :label="$t('forecast_sheet.additional_files')"
                          class-name="col-md-12"/>
          </div>
        </div>
        <div class="float-right">
          <button class="btn btn-success"
                  @click=openDialogValidate()>
            {{ $t('validate_send') }}
          </button>
          <button class="btn btn-primary"
                  style="margin-left: 10px" @click=createSheet()>
            {{ $t('save_draft') }}
          </button>
        </div>
      </AkFormSubmitted>
    </template>

    <template v-slot:extra>
      <AkDialog ref="dialogValidate"
                :title="$t('forecast_sheet.validate_dialog')"
                :validate-label="$t('validate')"
                width="450px"
                @validate="createAndValidate()">
        <div class="confirmation-content" style="margin-bottom: 10px">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('forecast_sheet.confirm_validation') }}</span>
        </div>
        <div class="form-row">
          <AkInputTextArea v-model="current.riComment"
                :label="$t('forecast_sheet.comment')"
                :submitted="this.submitted"
                class-name="col-12"
                placeholder=" "/>
        </div>
      </AkDialog>
    </template>
  </AkFormView>
</template>